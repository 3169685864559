





















import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useLeasing } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { LabelInValue } from "@/types";
import { ListLeasingHeaderDto } from "@interface/leasing";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectLeasing extends Vue {
  @Prop({ required: false, type: [String, Object], default: undefined })
  value!: string | undefined;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ required: false, type: Boolean, default: false })
  labelInValue!: boolean;

  @Prop({ required: false, type: String, default: "leasingNumber" })
  accessor!: "leasingNumber" | "leasingContractNumber";

  @Prop({ required: false, type: String })
  placeholder!: string;

  @Prop({ required: false, type: Array as () => string[], default: () => [] })
  readonly status!: string[];

  options: Option<ListLeasingHeaderDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.getOptions();
  }

  getOptions(): void {
    const params = new RequestQueryParams();
    if (this.status.length) {
      params.search = this.buildSearchByStatus(this.status);
    }
    this.fetchOptions(params);
  }

  buildSearchByStatus(statuses: string[]): string {
    return statuses
      .map(status => {
        return new SearchBuilder().push(["status", status]).build();
      })
      .join(SearchBuilder.OR);
  }

  fetchOptions(params: RequestQueryParams = new RequestQueryParams()): void {
    const { findAll, toOptions } = useLeasing();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.options = toOptions(this.accessor, res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e?: LabelInValue | string): void {
    if (!e) {
      this.getOptions();
    }

    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val?: string): void {
    const { filterBy } = useLeasing();
    const params = new RequestQueryParams();
    const queries: string[] = [];

    if (this.status.length) {
      queries.push(this.buildSearchByStatus(this.status));
    }

    if (val && this.accessor === "leasingNumber") {
      queries.push(
        filterBy({
          leasingNumber: val,
        })
      );
    }

    if (val && this.accessor === "leasingContractNumber") {
      queries.push(
        filterBy({
          leasingContractNumber: val,
        })
      );
    }

    params.search = queries.join(SearchBuilder.AND);

    this.fetchOptions(params);
  }

  findOption(
    value?: string | LabelInValue
  ): Option<ListLeasingHeaderDto> | undefined {
    if (typeof value === "string") {
      return this.options.find(e => value === e.value);
    }

    return this.options.find(e => value?.key === e.value);
  }
}
